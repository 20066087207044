import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {StaticQuery, graphql} from 'gatsby';
import {getImage, getSrc} from "gatsby-plugin-image";

const SEO = function({description, lang, meta, keywords, title, ogImage}) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description;

        let ogImageSrc = ogImage ? getSrc(ogImage) : getSrc(data.pic);
        let ogImageData = ogImage ? getImage(ogImage) : getImage(data.pic);

          return (
          <Helmet
            htmlAttributes={{lang}}
            title={title}
            meta={[
              {
                name: 'description',
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: title,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                name: 'twitter:card',
                content: 'summary',
              },
              {
                name: 'twitter:title',
                content: title,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
              {
                property: 'og:image',
                content: `${data.site.siteMetadata.url}${ogImageSrc}`,
              },
              {
                property: 'og:image:width',
                content: ogImageData.width,
              },
              {
                property: 'og:image:height',
                content: ogImageData.height,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                    name: 'keywords',
                    content: keywords.join(', '),
                  }
                  : []
              )
              .concat(meta)}
          />
        );
      }}
    />
  );
};

SEO.defaultProps = {
  lang: 'pt',
  meta: [],
  keywords: [],
  ogImage: null,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  ogImage: PropTypes.object,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        url
        title
        description
      }
    }

    pic: file(relativePath: {eq: "og-image.jpg"}) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 300, height: 300)
      }
    }
  }
`;
